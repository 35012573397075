<template>
    <div>
        <b-card no-body class="mb-0 mt-0">
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6">
                        <h5>Offloading Data</h5>
                        <b-button
                            size="sm"
                            variant="outline-success"
                            @click="openFile()"
                        >
                            <span>Run Allow</span>
                        </b-button>
                        <hr style="color: #000000;">
                        <b-row>
                            <b-col cols="5">
                                <b-form-group label-for="number" label="TF Number">
                                    <validation-provider #default="{ errors }" rules="">
                                        <b-form-select
                                            size="sm"
                                            id="number"
                                            name="number"
                                            v-model="buying.transport_id"
                                            @change="transportInfo()"
                                        >
                                        <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="transport in transports.filter((e) => e.transaction.code.toLowerCase() == 'ps')"
                                                :key="transport.id"
                                                :value="transport.id"
                                            >
                                                {{ transport.number }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="7">
                                <b-form-group label-for="tfNumber" label="System TF Number">
                                    <validation-provider #default="{ errors }" rules="">
                                        <b-form-select
                                            size="sm"
                                            id="tfNumber"
                                            name="tfNumber"
                                            v-model="buying.transport_id"
                                            @change="transportInfo()"
                                        >
                                        <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="transport in transports"
                                                :key="transport.id"
                                                :value="transport.id"
                                            >
                                                {{ transport.tfNumber }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </validation-provider>
                                </b-form-group>
                                
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="5">
                                <b-form-group label-for="truck" label="Truck Number">
                                    <validation-provider #default="{ errors }" name="Truck Number" rules="">
                                        <b-form-input
                                            id="truck"
                                            name="truck"
                                            size="sm"
                                            v-model="buying.truck"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="7">
                                <b-form-group label-for="transporter" label="Transporter">
                                    <validation-provider #default="{ errors }" name="Transporter" rules="">
                                        <b-form-input
                                            id="transporter"
                                            name="transporter"
                                            size="sm"
                                            v-model="buying.transporter"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="5">
                                <b-form-group label-for="license" label="Driver License">
                                    <validation-provider #default="{ errors }" name="Driver License" rules="">
                                        <b-form-input
                                            id="license"
                                            name="license"
                                            size="sm"
                                            v-model="buying.license"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="7">
                                <b-form-group label-for="driver" label="Driver Name">
                                    <validation-provider #default="{ errors }" name="Driver Name" rules="">
                                        <b-form-input
                                            id="driver"
                                            name="driver"
                                            size="sm"
                                            v-model="buying.driver"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="5">
                                <b-form-group label-for="bales" label="Bales">
                                    <validation-provider #default="{ errors }" name="Bales" rules="">
                                        <b-form-input
                                            id="bales"
                                            name="bales"
                                            size="sm"
                                            v-model="buying.bales"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="5">
                                <b-form-group label-for="load" label="KGs">
                                    <validation-provider #default="{ errors }" name="KGs" rules="">
                                        <b-form-input
                                            id="load"
                                            name="load"
                                            size="sm"
                                            v-model="buying.load"
                                            disabled
                                        />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" md="6">
                        <validation-observer ref="dataForm" #default="{ invalid }">
                            <b-form ref="form" @submit.stop.prevent="handleUpdate">
                                <div class="d-flex justify-content-between">
                                    <h5>Pre Buying Data Capturing</h5>
                                    <b-button
                                        size="sm"
                                        variant="outline-success"
                                        @click="connectSerialPort()"
                                    >
                                        <span>Connect Scale</span>
                                    </b-button>
                                </div>
                                <hr style="color: #000000;">
                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="code" label="M/CODE" v-if="station.type !== 4">
                                            <validation-provider #default="{ errors }" name="M/CODE" rules="">
                                                <b-form-input
                                                    id="code"
                                                    name="code"
                                                    size="sm"
                                                    v-model="station.station.code"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>

                                        <b-form-group label-for="code" label="M/CODE" v-if="station.type === 4">
                                        <validation-provider #default="{ errors }" name="M/CODE" rules="">
                                            <b-form-input
                                                id="code"
                                                name="code"
                                                size="sm"
                                                v-model="station.station.market.code"
                                                disabled
                                            />
                                        </validation-provider>
                                    </b-form-group>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-group label-for="name" label="Market Name" v-if="station.type === 4">
                                            <validation-provider #default="{ errors }" name="Market Name" rules="">
                                                <b-form-input
                                                    id="name"
                                                    name="name"
                                                    size="sm"
                                                    v-model="station.station.market.name"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-group label-for="sale_id" label="Sale #" v-if="station.type !== 4">
                                            <validation-provider #default="{ errors }" name="sale_id" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    id="sale_id"
                                                    name="sale_id"
                                                    v-model="buying.sale_id"
                                                    @change="populateSocieties()"
                                                >
                                                <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="sale in station.station.sales.filter((e) => e.online)"
                                                        :key="sale.id"
                                                        :value="sale.id"
                                                    >
                                                        {{ sale.number }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                        <b-form-group label-for="sale_id" label="Sale #" v-if="station.type === 4">
                                            <validation-provider #default="{ errors }" name="sale_id" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    id="sale_id"
                                                    name="sale_id"
                                                    v-model="buying.sale_id"
                                                    @change="populateSocieties()"
                                                >
                                                <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="sale in station.station.market.sales.filter((e) => e.online)"
                                                        :key="sale.id"
                                                        :value="sale.id"
                                                    >
                                                        {{ sale.number }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="ps_code" label="PS CODE">
                                            <validation-provider #default="{ errors }" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    id="ps_code"
                                                    name="ps_code"
                                                    v-model="buying.society_id"
                                                    @change="getSaleGrowers()"
                                                >
                                                <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="society in societies"
                                                        :key="society.id"
                                                        :value="society.id"
                                                    >
                                                        {{ society.code.toUpperCase() }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-group labe-for="society_id" label="Society Name">
                                            <validation-provider #default="{ errors }" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    id="society_id"
                                                    name="society_id"
                                                    v-model="buying.society_id"
                                                    @change="getSaleGrowers()"
                                                >
                                                <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="society in societies"
                                                        :key="society.id"
                                                        :value="society.id"
                                                    >
                                                        {{ society.name.toUpperCase() }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="registrationNumber" label="Grower #">
                                            <validation-provider #default="{ errors }" name="Grower #" rules="">
                                                <b-form-input
                                                    id="registrationNumber"
                                                    name="registrationNumber"
                                                    size="sm"
                                                    v-model="buying.registrationNumber"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="5">
                                        <b-form-group label-for="growerName" label="Grower Name">
                                            <validation-provider #default="{ errors }" name="Grower Name" rules="">
                                                <b-form-input
                                                    id="growerName"
                                                    name="growerName"
                                                    size="sm"
                                                    v-model="buying.growerName"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="3">
                                        <b-form-group label-for="kgs" label="Sold KGs">
                                            <validation-provider #default="{ errors }" name="Sold KGs" rules="">
                                                <b-form-input
                                                    id="kgs"
                                                    name="kgs"
                                                    size="sm"
                                                    v-model="buying.kgs"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="tag" label="Bale Tag">
                                            <validation-provider #default="{ errors }" name="Bale Tag" rules="">
                                                <b-form-input
                                                    ref="refTag"
                                                    id="tag"
                                                    name="tag"
                                                    size="sm"
                                                    v-model="buying.tag"
                                                    autofocus
                                                    @keyup.enter.prevent="validateTag()"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.tag">{{ serverErrors.tag[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="5">
                                        <b-form-group label-for="mass" label="KGs">
                                            <validation-provider #default="{ errors }" name="mass" rules="">
                                                <b-form-input
                                                    ref="refMass"
                                                    id="mass"
                                                    name="mass"
                                                    size="lg"
                                                    v-model="buying.mass"
                                                    disabled
                                                    style="font-weight:bold;font-size:1.5em;"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.mass">{{ serverErrors.mass[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="3">
                                        <b-form-group label-for="count" label="Bale Count">
                                            <validation-provider #default="{ errors }" name="Bale Count" rules="">
                                                <b-form-input
                                                    id="count"
                                                    name="count"
                                                    size="sm"
                                                    v-model="buying.count"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <!-- <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Grade</span>
                            </b-button>
                        </div>
                    </b-col> -->
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive 
                :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(ticket)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.barcode }}</span>
                </template>

                <template #cell(grade)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.grade }}</span>
                </template>

                <template #cell(number)="data">
                    <span v-if="data.item.grower != null">{{ data.item.grower.number }}</span>
                </template>

                <template #cell(grower)="data">
                    <span v-if="data.item.grower != null">{{ data.item.grower.name.toUpperCase() }}</span>
                </template>

                <!-- Column: Actions -->
                <!-- <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template> -->
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import store from '@/store'
    import axios from '@axios'
    import inlineStoreModule from '@/views/cromis/buying/inlineStoreModule'
    import useInlineList from '@/views/cromis/buying/useInlineList'
    import { exec } from 'child_process';

    export default {
        props: {},
        components: {
            vSelect,
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            // const { exec } = require('child_process');

            const serverErrors = ref(null)
            const saving = ref(false)
            const dataForm = ref(null)
            const refTag = ref(null)
            const refTicket = ref(null)
            const refMass = ref(null)

            const station = ref(JSON.parse(localStorage.getItem('userData')).station)

            const societies = ref([])
            const growers = ref([])
            const transports = ref([])

            const port = ref(null)

            const buying = ref({
                sale_id: null,
                society_id: null,
                tag: null,
                ticket: null,
                ticket_id: null,
                mass: null,
                kgs: null,
                count: null,
                societyCode: null,
                societyName: null,
                growerName: null,
                registrationNumber: null,
                grower_id: null,
                truck: null,
                transporter: null,
                license: null,
                driver: null,
                transport_id: null,
                bales: null,
                load: null,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-inline'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, inlineStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(() => {
                store.dispatch('cromis-inline/transports')
                    .then(response => {
                        transports.value = response.data.transports
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
            })

            const getSaleGrowers = async () => {
                if(buying.value.society_id !== null){
                    await store.dispatch('cromis-inline/sale_growers', {sale_id: buying.value.sale_id, society_id: buying.value.society_id })
                            .then(response => {
                                growers.value = response.data.growers
                                context.refs['refTag'].focus()
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
                }
                else{
                    growers.value = []
                }
            }

            const populateSocieties = async () => {
                if(buying.value.sale_id !== null){
                    buying.value.society_id = null
                    societies.value = station.value.type !== 4
                                        ? station.value.station.sales.find(sale => sale.id == buying.value.sale_id).societies
                                        : station.value.station.market.sales.find(sale => sale.id == buying.value.sale_id).societies
                }
                else{
                    societies.value = []
                    buying.value.society_id = null
                }

                sale_id.value = buying.value.sale_id

                refTag.value.focus()
            }

            const transportInfo = async () => {
                if(buying.value.transport_id != null){
                    let transport = transports.value.filter((e) => e.id == buying.value.transport_id)[0]

                    for(let i = 0; i < transport.fleets.length; i++){
                        if(i == 0){
                            buying.value.truck = transport.fleets[i].plateNumber
                        }
                        else{
                            buying.value.truck += ' / ' + transport.fleets[i].plateNumber
                        }
                    }

                    buying.value.driver = transport.driver.fullName.toUpperCase()
                    buying.value.license = transport.driver.licence
                    buying.value.transporter = transport.transporter.code.toUpperCase() + ': ' + transport.transporter.name.toUpperCase()

                    await store.dispatch('cromis-inline/tickets', {id: transport.id })
                                .then(response => {
                                    if(response.data.transport != null){
                                        buying.value.bales = response.data.transport.offloaded.length.toLocaleString()
                                        buying.value.load = response.data.transport.offloaded.length > 0 ? response.data.transport.offloaded.reduce((sum, ticket) => sum + ticket.mass, 0).toLocaleString() : 0
                                    }
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    buying.value.truck = null
                    buying.value.driver = null
                    buying.value.license = null
                    buying.value.transporter = null
                    buying.value.bales = null
                    buying.value.load = null
                }
            }

            const connectSerialPort = async () => {
                console.log(navigator)
                // if(!('serial' in navigator)){
                //     console.error('Serial port is not supported by this browser')
                //     return
                // }

                try{
                    console.log(navigator)
                    // Request and open the port
                    port.value = await navigator.serial.requestPort()
                    console.log('Serial port selected')

                    await port.value.open({
                        baudRate: 9600,
                        dataBits: 8,
                        stopBits: 1,
                        flowControl: 'none',
                    })

                    // Create a TextDecoderStream to convert bytes to text
                    const decoder = new TextDecoderStream()
                    port.value.readable.pipeTo(decoder.writable)
                    const reader = decoder.readable.getReader()

                    let buffer = '';  // Buffer to accumulate incoming data

                    readSerialData(reader, buffer)
                }
                catch(error){
                    console.error('Error opening serial port', error)
                }
            }

            const readSerialData = async (reader, buffer) => {
                try{
                    while(true){
                        const { value, done } = await reader.read()

                        if(done){
                            console.log('Stream closed');
                            break;
                        }

                        if(value){
                            // Accumulate the data in the buffer
                            // buffer += value;
                            console.log('Received data:', value)

                            // Look for complete lines (delimited by '\r\n')
                            // let lines = buffer.split('\r\n')

                            processLine(value)

                            // Process all lines except the last (incomplete)
                            // for (let i = 0; i < lines.length - 1; i++) {
                            //     processLine(lines[i])
                            // }

                            // Keep the incomplete line in the buffer
                            // buffer = lines[lines.length - 1];
                        }
                    }
                }
                catch(error){
                    console.error('Error reading serial data', error)
                }
            }

            function extractTextBetweenControlChars(input) {
                // Regular expression to match text between \u0002 and \u0003
                let match = input.match(/\u0002(.*?)\u0003/);

                // Return extracted text or null if no match is found
                return match ? match[1].trim() : null;
            }

            const processLine = (line) => {
                let result = extractTextBetweenControlChars(line.replace('K', '').replace('k', '').replace('G', '').replace('g', '').replaceAll(' ', ''));
                console.log('Processing line:', line.replace('K', '').replace('k', '').replace('G', '').replace('g', '').replaceAll(' ', ''));

                // Example line: 'S S 176.161 g'
                // if(line.trim().length > 0 && line.includes("g")){
                //     // Extract only the numeric weight value
                //     const weightData = line.trim().replace(/[^0-9.]/g, '');
                //     if (weightData) {
                //         buying.value.mass = weightData;
                //         console.log('Weight:', `${weightData} g`);
                //     }
                // }

                if(result != null){
                    console.log('============NDANI=============')
                    console.log(result)
                    console.log('==========NJE===============')

                    buying.value.mass = result.replace('K', '').replace('k', '').replace('G', '').replace('g', '').replaceAll(' ', '')
                }
            }

            // connectSerialPort()

            const validateTag = async () => {
                let valid = false

                if(buying.value.tag !== null && buying.value.tag.length >= 16){
                    let growerNumber = buying.value.tag.substr(7, 4)
                    let grower = null;
                    let society = null;
                    grower = growers.value.find(grower => grower.registrationNumber === growerNumber)

                    if(grower !== undefined && grower !== null){
                        if(station.value.type !== 4){
                            station.value.station.sales.find(sale => ''+sale.id == ''+buying.value.sale_id).societies.forEach(e => {
                                if(e.id == grower.growable_id){
                                    society = e
                                }
                            })
                        }
                        else{
                            station.value.station.market.sales.find(sale => ''+sale.id == ''+buying.value.sale_id).societies.forEach(e => {
                                if(e.id == grower.growable_id){
                                    society = e
                                }
                            })
                        }
                    }

                    if(grower === undefined || grower === null){
                        serverErrors.value = { 'tag': ['No grower with the scanned tag'] }
                    }
                    else{
                        if(society === null){
                            serverErrors.value = { 'tag': [''+grower.name + ' is not for this sale'] }
                        }
                        else{
                            buying.value.registrationNumber = grower.registrationNumber
                            buying.value.growerName = grower.name
                            buying.value.societyCode = society.code
                            buying.value.societyName = society.name
                            buying.value.grower_id = grower.id
                            valid = true
                        }
                    }
                }

                if(!valid){
                    buying.value.tag = null
                    context.refs['refTag'].focus()
                }
                else{
                    serverErrors.value = null
                    handleUpdate(buying.value)
                }
            }

            const validateTicket = async () => {
                let valid = false

                if(!valid){

                }
                else{
                    serverErrors.value = null
                    context.refs['refTicket'].focus()
                }
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filters
                sale_id,
            } = useInlineList()

            const handleUpdate = async (item) => {
                saving.value = true

                await store.dispatch('cromis-inline/buy', {
                            sale_id: item.sale_id,
                            transport_id: item.transport_id,
                            ticket_id: item.ticket_id,
                            barcode: item.tag,
                            grower_id: item.grower_id,
                            mass: item.mass,
                        }).then(response => {
                            saving.value = false

                            if(response.data.transport.transport != null){
                                buying.value.bales = response.data.transport.transport.offloaded.length.toLocaleString()
                                buying.value.load = response.data.transport.transport.offloaded.length > 0 ? response.data.transport.transport.offloaded.reduce((sum, ticket) => sum + ticket.mass, 0).toLocaleString() : 0
                            }

                            if(response.data.growerTickets.length > 0){
                                buying.value.count = response.data.growerTickets.length.toLocaleString()
                                buying.value.kgs = response.data.growerTickets.reduce((sum, ticket) => sum + ticket.mass, 0).toLocaleString()
                            }

                            // axios.post('http://192.168.7.201:3000/run-bat').then((resp) => {
                            //     console.log(resp.data)
                            // })

                            axios.post('https://localhost:3000/run-bat').then((resp) => {
                                console.log(resp.data)
                            })

                            // store.dispatch('cromis-inline/runBatFile', {

                            // })
                            // .then(response => {
                            //     console.log(response)
                            // })
                            // .catch(error => {
                            //     console.log(error)
                            // })

                            buying.value.tag = null
                            refTag.value.focus()

                            refetch()
                        })
                        .catch(error => {
                            saving.value = false
                            if(error.response.status === 422){
                                serverErrors.value = error.response.data.errors
                            }
                            else{
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    timer: 3000,
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            }
                        })
            }

            const openFile = async () => {
                // var wshShell = new ActiveXObject("WScript.Shell")
                // wshShell.Run("notepad.exe")

                // exec("notepad.exe", (error, stdout, stderr) => {
                //     if (error) {
                //         res.status(500).send(`Error: ${error.message}`);
                //         return;
                //     }
                //     if (stderr) {
                //         res.status(500).send(`Stderr: ${stderr}`);
                //         return;
                //     }

                //     res.send(`Output: ${stdout}`);
                // });

                // store.dispatch('cromis-inline/runBatFile', {})

                axios.post('https://localhost:3000/run-bat').then((resp) => {
                    console.log(resp.data)
                })

                // store.dispatch('cromis-inline/runBatFile', {

                // }).then(response => {
                //     console.log(response)
                // })
                // .catch(error => {
                //     console.log(error)
                // })
            }

            return {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filters
                sale_id,

                serverErrors,
                saving,
                dataForm,
                station,
                buying,
                societies,
                growers,
                transports,
                transportInfo,
                populateSocieties,
                getSaleGrowers,
                connectSerialPort,
                openFile,
                validateTag,
                validateTicket,
                handleUpdate,
                refTag,
                refTicket,
                refMass,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>